import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API, config } from '../config';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName,
                                avatar: user.photoURL
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    // Firebase ile Google giriş işlemi
    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        return firebase.auth().signInWithPopup(provider);
    };

    // Kullanıcı hesabını oluştur veya işlemi atla
    const createOrSkipAccount = async () => {
        try {
            const user = await new Promise((resolve) => {
                firebase.auth().onAuthStateChanged(resolve);
            });

            if (!user) return;

            const token = await user.getIdToken();
            const { displayName, email, photoURL } = user;

            const [firstName, ...lastNameParts] = displayName.split(' ');
            const lastName = lastNameParts.join(' ');

            const apiBaseURL = config.API_SERVER;
            const headers = { Authorization: `Bearer ${token}` };

            const userAPIURL = `${apiBaseURL}users?email=${email}`;
            const userResponse = await axios.get(userAPIURL, { headers });
            const existingUser = userResponse.data[0];

            if (existingUser) {
                if (existingUser.customer_id === null) {
                    const stripeEndpoint = 'stripe';
                    const stripeAPIURL = `${apiBaseURL}${stripeEndpoint}`;
                    const stripeRequestBody = { user: existingUser.id };
                    await axios.post(stripeAPIURL, stripeRequestBody, { headers });
                }
            } else {
                const userEndpoint = 'users';
                const newUserPayload = {
                    username: email,
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    avatar_url: photoURL
                };
                const newUserURL = `${apiBaseURL}${userEndpoint}`;
                await axios.post(newUserURL, newUserPayload, { headers });
            }
        } catch (error) {
            console.error('Account creation or skip failed:', error);
        }
    };

    // const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const firebaseRegister = async (email, password, fname, lname) => {
        try {
            await firebase.auth().createUserWithEmailAndPassword(email, password);

            await firebase
                .auth()
                .currentUser.updateProfile({
                    displayName: `${fname} ${lname}`
                })
                .then(() => {
                    createOrSkipAccount();
                })
                .catch((error) => {
                    console.error('Error updating profile:', error);
                });
        } catch (error) {
            console.error('Error creating user or updating profile:', error);
            // Handle other errors as needed
        }
    };

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                createOrSkipAccount
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
