// third-party
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { config } from '../../config';
import { openSnackbar } from 'store/slices/snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    store: null,
    storeCards: null,
    orders: [],
    isLoading: false
};

const slice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET STORES
        getStoreCardsSuccess(state, action) {
            state.storeCards = action.payload;
        },
        // FILTER ORDERS
        filterOrdersSuccess(state, action) {
            state.orders = action.payload;
        },
        // SET LOADING STATUS
        setIsLoadingSuccess(state, action) {
            state.isLoading = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStoreCards() {
    return async () => {
        try {
            dispatch(slice.actions.setIsLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const url = `${config.API_SERVER}stores/filter?email=${email}`;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            dispatch(slice.actions.getStoreCardsSuccess(response.data));
            dispatch(slice.actions.setIsLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function filterOrders(id) {
    return async () => {
        try {
            dispatch(slice.actions.setIsLoadingSuccess(true));
            const token = await firebase.auth().currentUser.getIdToken();
            // const email = firebase.auth().currentUser.email;
            const bearer = 'Bearer ';
            const url = `${config.API_SERVER}marketplace-orders?store=${id}`;
            const response = await axios.get(url, { headers: { Authorization: bearer + token } });
            console.log('etsy orders:', response.data);
            dispatch(slice.actions.filterOrdersSuccess(response.data));
            dispatch(slice.actions.setIsLoadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log('error: ', error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.status + ' ' + error.response.statusText,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}
